/* src/app/pages/kiosk/kiosk.component.scss */
.app-container {
  height: 100vh;
  width: 100vw;
  z-index: 0;
  display: flex;
}
.background-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  z-index: -1;
}
.background-image-container img {
  width: 100%;
  height: 100%;
}
.idle-state-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height: 100%;
  background: #00cdff;
}
.header-container {
  z-index: 2;
  grid-area: header-container;
}
.header-container app-header {
  height: 100%;
  display: flex;
}
.side-menu-container {
  grid-area: side-menu-container;
  width: 25%;
  z-index: 3;
  position: relative;
}
.content-container {
  position: relative;
  z-index: 0;
  width: 75%;
  grid-area: content-container;
}
.content-container .side-menu-layout-1 {
  height: 100%;
  width: 22%;
  top: 0;
}
.content-container .side-menu-layout-2 {
  height: 70%;
  width: 34%;
  transition: height 100ms linear;
}
.content-container .side-menu-full-screen {
  height: 100%;
  transition: height 0.25s linear;
}
.content-container .side-sub-menu-animate {
  position: absolute;
  bottom: 0;
  z-index: 2;
}
.search-container {
  align-self: stretch;
  position: absolute;
  height: 100%;
  width: 81%;
  z-index: 2;
  left: 19%;
}
.modal-container-lax {
  transition: top ease-out 200ms;
  position: fixed;
  top: 1080px;
  z-index: 3;
  width: calc(100% - 480px) !important;
}
body,
html {
  height: 100%;
  margin: 0;
  width: 100%;
}
.modal-container {
  transition: top ease-out 200ms;
  position: fixed;
  top: 1080px;
  z-index: 10;
}
.video-modal-container {
  position: absolute;
  z-index: 10000;
  top: 0;
}
app-static-content {
  z-index: 10;
  width: 100%;
}
.video-chat-container {
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
}
.fade-in {
  opacity: 0;
  animation: fadeIn 2s forwards;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.loader {
  background: #00a6ce;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: fixed;
  display: flex;
  height: 100vh;
  width: 100vw;
  z-index: 10;
}
.loader img {
  margin-top: 4.8%;
  width: 30em;
}
.loader .loading-info {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}
.loader .loading-info span {
  color: white;
}
.slide-flights-left {
  position: absolute;
  left: -68%;
  top: -13%;
  z-index: 3;
  height: 115%;
}
.static-info-container {
  position: absolute;
  z-index: 1000;
  display: flex;
  height: 89vh;
  width: 100%;
  bottom: 0%;
}
.static-info-container-lax {
  position: absolute;
  z-index: 1000;
  display: flex;
  height: 70%;
  width: 100%;
  bottom: 0%;
  top: 30%;
}
.idle-state-page {
  z-index: 100;
}
.invisible-container {
  height: 5rem;
  width: 5rem;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.invisible-container img {
  width: 2rem;
}
@keyframes movesRight {
  0% {
    left: -68%;
  }
  100% {
    left: 0px;
  }
}
@keyframes movesLeft {
  0% {
    left: 0px;
  }
  100% {
    left: -68%;
  }
}
@keyframes movesLeftLAX {
  0% {
    left: 0px;
  }
  100% {
    left: -100%;
  }
}
.lax-tv-container {
  position: absolute;
  z-index: 10000;
  display: flex;
  height: 70%;
  width: 100%;
  bottom: 0%;
}
.lax-tv-container app-lax-tv {
  width: 100%;
}
@media screen and (max-width: 900px) {
  .modal-container-lax {
    width: calc(100% - 480px) !important;
  }
}
@media screen and (max-width: 700px) {
  .modal-container-lax {
    width: calc(100% - 480px) !important;
  }
}
@media screen and (min-width: 3840px), (min-height: 2160px) {
  .loader img {
    margin-top: 4.8%;
    width: 60em;
  }
  .loader .loading-info {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    display: flex;
  }
  .loader .loading-info span {
    font-size: 2.5em !important;
    transition: font-size 500ms linear;
  }
  .modal-container-lax {
    width: 75% !important;
  }
}
@media screen and (max-width: 1919px) {
  .grid-layout-2 .side-menu-container {
    min-width: 480px;
  }
  .grid-layout-2 .content-container .side-sub-menu-animate {
    min-width: 480px;
  }
  .modal-container-lax {
    width: calc(100% - 480px) !important;
  }
}
@media screen and (width: 1920px) {
  .side-menu-layout-2 {
    max-width: 480px;
  }
}
.sub-menu {
  position: absolute;
  min-width: 480px;
  height: 70%;
  width: 25%;
  bottom: 0;
}
/*# sourceMappingURL=kiosk.component-TQMC4DEM.css.map */
