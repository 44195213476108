import { AppConfig } from '../../environments/environment';
import { logEventModel } from '@app/models/log.model';
import { selectDataBus } from '@app/store/selectors';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { TimeConversionService } from './time-conversion.service';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    body: { data: string };
    lastUpdated: string;

    timeService = inject(TimeConversionService);

    constructor(private store: Store<any>, private http: HttpClient) {
        const dataBusSelector = this.store.select(selectDataBus);

        dataBusSelector.subscribe((dataBusItem) => {
            this.lastUpdated = dataBusItem.lastUpdated;
        });
    }

    getFlightsLax() {
        let date = this.timeService.getAfterFlightTime();

        return this.http.get<any>(
            `${AppConfig.laxEndpoint.url}/getFlights?showFlightsAfter=${date}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-api-Key': `${AppConfig.laxEndpoint.apiKey}`,
                },
            }
        );
    }

    getWeatherInformation() {
        return this.http.get<any>(
            `${AppConfig.weatherInformation.url}/weather/v1/current/${AppConfig.weatherInformation.airportCode}?temperatureScale=F&lengthUnit=M`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-apiKey': `${AppConfig.weatherInformation.apiKey}`,
                },
            }
        );
        // .pipe();
    }

    saveSelfie(data?: string) {
        this.body = {
            data: data,
        };

        return this.http.post<any>(
            `${AppConfig.laxEndpoint.url}/helpers/saveSelfie`,
            JSON.stringify(this.body),
            {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': `${AppConfig.laxEndpoint.apiKey}`,
                },
            }
        );
    }

    submitSurvey(body: any) {
        return this.http.post<any>(
            `${AppConfig.laxEndpoint.url}/helpers/submitSurvey`,
            JSON.stringify(body),
            {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': `${AppConfig.laxEndpoint.apiKey}`,
                },
            }
        );
    }

    onLogEvent(body: logEventModel, userToken) {
        return this.http.post<any>(
            `${AppConfig.laxEndpoint.url}/logEvent`,
            JSON.stringify(body),
            {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': `${AppConfig.laxEndpoint.apiKey}`,
                    Authorization: `Bearer ${userToken}`,
                },
            }
        );
    }

    onLogDoubleTap(body: { kioskName: string }) {
        return this.http.post<any>(
            `${AppConfig.laxEndpoint.url}/logDoubleTap`,
            JSON.stringify(body),
            {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': `${AppConfig.laxEndpoint.apiKey}`,
                },
            }
        );
    }
}
