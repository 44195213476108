import { GoogleAnalyticsService } from './services/google-analytics.service';
import { ElectronService } from './providers/electron.service';
import { AppConfig } from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { setComputerName } from './store/actions';
import { Component, OnInit } from '@angular/core';
import { selectAgent } from './store/selectors';
import { Router, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { Loader } from '@googlemaps/js-api-loader';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, NgFor],
})
export class AppComponent implements OnInit {
    iconNames = [
        '01.png',
        '02.png',
        '03.png',
        '04.png',
        '05.png',
        '06.png',
        '07.png',
        '08.png',
        '11.png',
        '12.png',
        '13.png',
        '14.png',
        '15.png',
        '16.png',
        '17.png',
        '18.png',
        '19.png',
        '20.png',
        '21.png',
        '22.png',
        '23.png',
        '24.png',
        '25.png',
        '26.png',
        '29.png',
        '30.png',
        '31.png',
        '32.png',
        '33.png',
        '34.png',
        '35.png',
        '36.png',
        '37.png',
        '38.png',
        '39.png',
        '40.png',
        '41.png',
        '42.png',
        '43.png',
        '44.png',
        'RestroomIcon.svg',
        'attraction_icon.svg',
        'disgusted.svg',
        'end-pin.svg',
        'family-restroom.svg',
        'happier.svg',
        'happiest.svg',
        'indifferent.svg',
        'live_tv.svg',
        'man-wc.svg',
        'partlyCloudy.svg',
        'restroom.svg',
        'start-pin.svg',
        'traffic.svg',
        'unhappy.svg',
        'woman-wc.svg',
    ];

    constructor(
        private googleAnalyticService: GoogleAnalyticsService,
        private electronService: ElectronService,
        private translate: TranslateService,
        private store: Store<any>,
        private router: Router
    ) {
        this.googleAnalyticService.createTracker();
        translate.setDefaultLang('en');
        this.translate.use('en');
        if (electronService.isElectron()) {
            this.store.dispatch(
                setComputerName({
                    computerName: electronService.getComputerName(),
                })
            );
        } else {
            this.store.dispatch(
                setComputerName({ computerName: AppConfig.kioskName })
            );
        }

        const loader = new Loader({
            apiKey: AppConfig.googleMapKey,
            version: 'weekly',
        });

        loader.load().then(() => {
            loader.importLibrary('geometry');
        });
    }

    ngOnInit() {
        if (this.electronService.isElectron()) {
            this.router.navigate(['/kiosk']);
        } else {
            if (AppConfig.isAgent) {
                const agentState$ = this.store.select(selectAgent);
                agentState$.subscribe((agent) => {
                    if (agent.agentToken == '')
                        this.router.navigate(['/login']);
                    else this.router.navigate(['/kiosk']);
                });
            } else {
                // if (this.getParameterByName("mode") === "DEMO") {
                this.router.navigate(['/kiosk']);
                // }
            }
        }
    }

    getParameterByName(name: string): string {
        var match = RegExp('[?&]' + name + '=([^&]*)').exec(
            window.location.href
        );
        if (match && decodeURIComponent(match[1].replace(/\+/g, ' '))) {
            return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
        } else {
            return null;
        }
    }
}
