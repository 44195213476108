import { selectDataBus, selectUI, selectPOI } from '@app/store/selectors';
import {
    TranslateService,
    LangChangeEvent,
    TranslateModule,
} from '@ngx-translate/core';
import { toggleSearchBarModel, showDialog } from '@app/store/actions';
import { sideMenuDummyData } from '../../dummyData/sideMenu.js';
import { SideMenuService } from '@app/services/side-menu.service';
import { WeatherInfo } from '@app/models/ui/weatherInfo.model';
import { UtilityService } from '@app/services/utility.service';
import { AppConfig } from '../../../environments/environment';
import { VERSION, GIT_REV } from 'environments/version';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { WeatherComponent } from '../weather/weather.component';
import { TimeComponent } from '../time/time.component';
import { NgIf, NgFor, NgClass, NgStyle, UpperCasePipe } from '@angular/common';

@Component({
    selector: 'app-side-menu-lax',
    templateUrl: './side-menu-lax.component.html',
    styleUrls: ['./side-menu-lax.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        TimeComponent,
        WeatherComponent,
        NgFor,
        NgClass,
        NgStyle,
        UpperCasePipe,
        TranslateModule,
    ],
})
export class SideMenuLaxComponent implements OnInit {
    sideMenuButtons = sideMenuDummyData.categories;
    isSubSideMenuInfoOpen: boolean;
    selectedCategory: string = '';
    weatherInfo: WeatherInfo = {
        shouldShow: false,
        imageUrl: '',
        value: '',
        unit: '',
    };
    isSubSideMenuOpen: boolean;
    dataBusSubscription;
    poiSubscription;
    uiSubscription;
    currentYear = new Date().getFullYear();
    hideADACategory: boolean;
    hideINFOCategory: boolean;
    isStaticInfoOpen: any;
    infoCategories: any;
    forceSelect = '';
    staticInfo;

    constructor(
        private sideMenuService: SideMenuService,
        public translate: TranslateService,
        public utility: UtilityService,
        private store: Store<any>
    ) {}

    ngOnInit() {
        this.dataBusSubscribe();
        this.poiSubscribe();
        this.uiSubscribe();

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.checkStaticInfoCategories(this.staticInfo);
        });
    }

    ngOnDestroy() {
        this.dataBusSubscription.unsubscribe();
        this.poiSubscription.unsubscribe();
        this.uiSubscription.unsubscribe();
    }

    uiSubscribe() {
        const uiState$ = this.store.select(selectUI);

        this.uiSubscription = uiState$.subscribe((uiItem) => {
            this.isSubSideMenuOpen = uiItem.windows.subSideMenu;
            this.isSubSideMenuInfoOpen = uiItem.windows.sideMenuInfo;
            this.isStaticInfoOpen = uiItem.windows.staticInfoModal;
        });
    }

    dataBusSubscribe() {
        const dataBusState$ = this.store.select(selectDataBus);
        this.dataBusSubscription = dataBusState$.subscribe(
            (dataBusItem: any) => {
                this.staticInfo = dataBusItem.staticInfo;
                if (dataBusItem.staticInfo)
                    this.checkStaticInfoCategories(this.staticInfo);
                else {
                    this.hideADACategory = true;
                    this.hideINFOCategory = true;
                } // Makes ADA and INFOs HIDE

                if (dataBusItem.weather.currentWeather) {
                    this.weatherInfo.unit =
                        dataBusItem.weather.temperatureScale;
                    this.weatherInfo.value =
                        dataBusItem.weather.currentWeather.temperature;
                    this.weatherInfo.imageUrl = `assets/icon/${dataBusItem.weather.currentWeather.icon}.png`;
                    this.utility.imageExists(
                        `assets/icon/${dataBusItem.weather.currentWeather.icon}.png`,
                        (exists) => {
                            this.weatherInfo.shouldShow = exists;
                        }
                    );
                }
            }
        );
    }

    checkStaticInfoCategories(info) {
        this.infoCategories = [];

        info.forEach((element) => {
            this.infoCategories.push(
                element.parentCategory[this.translate.currentLang]
            );
        });

        if (this.infoCategories.includes(this.translate.instant('ADA')))
            this.hideADACategory = false;
        else this.hideADACategory = true;

        if (
            this.infoCategories.includes(
                this.translate.instant('INFO_AND_TIPS')
            )
        )
            this.hideINFOCategory = false;
        else this.hideINFOCategory = true;
    }

    poiSubscribe() {
        const poiState$ = this.store.select(selectPOI);

        this.poiSubscription = poiState$.subscribe((poiItem) => {
            this.selectedCategory = poiItem.selectedCategory;
        });
    }

    onMenuButtonClick(categoryData) {
        this.store.dispatch(toggleSearchBarModel({ shouldOpen: false })); //To close search section if it is opened

        let componentState = {
            isSubSideMenuInfoOpen: this.isSubSideMenuInfoOpen,
            selectedCategory: this.selectedCategory,
            isSubSideMenuOpen: this.isSubSideMenuOpen,
            isStaticInfoOpen: this.isStaticInfoOpen,
        };
        if (this.forceSelect === categoryData.storeName) {
            this.forceSelect = '';
        } else {
            this.forceSelect = categoryData.storeName;
        }
        if (
            categoryData.storeName === 'selfie' ||
            categoryData.storeName === 'ADA' ||
            categoryData.storeName === 'INFO & TIPS' ||
            categoryData.storeName === 'LAX TV'
        ) {
            setTimeout(() => {
                this.forceSelect = '';
            }, 500);
        }
        this.sideMenuService.sideMenuButtonClicked(
            categoryData,
            componentState
        );
    }

    onLogoDoubleClick() {
        this.store.dispatch(
            showDialog({
                showDialog: true,
                title: `${AppConfig.pageName} v${VERSION} (${GIT_REV})`,
                subTitle: `© SITA ${this.currentYear}. ${this.translate.instant(
                    'RIGHTS_RESERVED'
                )}`,
                showCloudFunction: false,
            })
        );
    }
}
