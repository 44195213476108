<div class="selfie-container" (click)="close()"></div>

<div class="selfie-inside-container">
    <div class="header-container">
        <div class="empty-div"></div>
        <div class="title-container">
            <span *ngIf="!showShareSelfiePhase">
                {{ 'TAKE_A_SELFIE' | translate }}
            </span>
            <span *ngIf="showShareSelfiePhase">
                {{ 'SHARE_THIS_SELFIE' | translate }}
            </span>
        </div>
        <div class="close-button-container">
            <div class="close-button ripple">
                <i class="material-icons camera-icon" (click)="close()">
                    close
                </i>
            </div>
        </div>
    </div>

    <div
        class="webcam-phase-container"
        *ngIf="showTakeSelfiePhase"
        [ngStyle]="{ flex: webcamImage ? '1' : 0.7 }"
    >
        <div class="no-webcam-container" *ngIf="!showSelfieModal">
            <div class="loader"></div>
        </div>

        <div
            [ngClass]="{
                'image-container': captureMode,
                flash: captureAnimation
            }"
            [ngStyle]="{
                opacity: showSelfieModal ? '1' : '0'
            }"
            style="width: 100%; height: 100%; opacity: 1; display: contents"
        >
            <img
                #waterMarkedImage
                *ngIf="webcamImage"
                loading="lazy"
                [src]="webcamImage.imageAsDataUrl"
                alt=""
                class="selfie-image"
            />

            <webcam
                *ngIf="showWebcam"
                [height]="277"
                [width]="500"
                [trigger]="triggerObservable"
                (imageCapture)="handleImage($event)"
                [allowCameraSwitch]="allowCameraSwitch"
                (cameraSwitched)="cameraWasSwitched($event)"
                [imageQuality]="0.92"
                (initError)="handleInitError($event)"
            ></webcam>
        </div>

        <div class="footer-content-container" *ngIf="captureMode">
            <div style="flex: 0.5"></div>

            <div class="selfie-buttons-container">
                <app-horizontal-button-icon
                    *ngFor="let button of buttonsInfo | slice : 0 : 2"
                    [buttonInfo]="button"
                    (onClick)="handleClick($event)"
                ></app-horizontal-button-icon>
            </div>
        </div>
    </div>

    <div class="webcam-phase-container" *ngIf="showShareSelfieLoader">
        <div class="no-webcam-container" [ngStyle]="{ background: 'unset' }">
            <div class="loader"></div>
        </div>
    </div>

    <div class="share-content-container" *ngIf="showShareSelfiePhase">
        <div style="flex: 0.2"></div>
        <div class="share-content-info">
            <div class="left-container">
                <div style="flex: 0.11"></div>
                <div class="qrcode-container">
                    <qrcode
                        [qrdata]="qrData"
                        [width]="qrCodeWidth"
                        [errorCorrectionLevel]="'M'"
                    ></qrcode>
                </div>

                <div class="scan-me-container">
                    <div class="title-container">
                        <span>
                            {{ 'SCAN_ME' | translate }}
                        </span>
                    </div>
                    <div class="sub-title-container">
                        <span>
                            {{ 'SCAN_ME_DESCRIPTION' | translate }}
                        </span>
                    </div>
                </div>
                <div style="flex: 0.2"></div>
            </div>
            <div class="right-container">
                <div style="flex: 0.2"></div>
                <div class="right-content-container">
                    <div style="flex: 0.2"></div>
                    <span class="text"> {{ 'VISIT' | translate }} </span>
                    <span id="url-text">laxphoto.lawa.org</span>
                    <span class="text"> {{ 'AND_ENTER' | translate }}</span>
                    <div class="code-container">
                        <app-mask-code
                            [code]="qrSelfieCode"
                            [isMasked]="isMasked"
                        >
                        </app-mask-code>
                    </div>

                    <div class="unmask-button-container">
                        <app-horizontal-button-icon
                            [buttonInfo]="buttonsInfo[2]"
                            (onClick)="handleClick($event)"
                            (mousedown)="handleEvent()"
                            (mouseup)="handleEvent()"
                            (touchstart)="handleEvent()"
                            (touchend)="handleEvent()"
                        ></app-horizontal-button-icon>
                    </div>
                </div>
            </div>
        </div>
        <div style="flex: 0.27"></div>
        <div class="or-circle-container">
            <span>OR</span>
        </div>
    </div>

    <div
        class="footer-container"
        *ngIf="!showShareSelfiePhase"
        [ngStyle]="{ display: captureMode ? 'none' : 'flex' }"
    >
        <div
            class="capture-button-container"
            *ngIf="!captureMode && !showShareSelfiePhase"
        >
            <span
                *ngIf="!selfieButtonClicked"
                class="capture-button ripple"
                (click)="countDownTimer()"
            >
                <span class="capture-inner-circle"></span>
            </span>

            <span *ngIf="selfieButtonClicked" class="selfie-timer-container">
                {{ selfieTimer }}
            </span>
            <div style="flex: 0.2"></div>
        </div>
    </div>
</div>
